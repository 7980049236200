import { deleteAPI, get, postAPI, putAPI } from "../ApiService";
import Swal from "sweetalert2";
export const SupplierList = async({page=0, recPerPage=100, searchKeyword, filters}) => {
    let data = {
        page: page,
        recPerPage: recPerPage,
        q: searchKeyword,
        filters:filters
    }
    let res = await postAPI('/supplier/list', data)
    return res;
}

export const SupplierCreate = async({payload}) => {
    let res = await postAPI('/supplier/save', payload)
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}

export const SupplierDetail = async({payload}) => {
    let res = await get('/supplier/details/'+payload)
    return res;
}

export const SupplierUpdate = async({payload}) => {
    let res = await putAPI('/supplier/update/'+payload?.ID, payload)
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}

export const SupplierDelete = async({payload}) => {
    return Swal.fire({
        text: 'Are you sure you want to delete?',
        confirmButtonText: 'Yes',
        showCancelButton: true,
      }).then(async (result) => {
        if (result.value) {
            let res = await deleteAPI('/supplier/delete/'+payload?.ID)
            return Swal.fire({
                text:res.message,
                timer: 1500,
            }).then(() => {
                return res;
            })
        }
      })
}