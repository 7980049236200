import React from 'react'
import { deleteAPI, postAPI, putAPI, get } from "../ApiService";
import Swal from "sweetalert2";
export const ProjectList = async({page=0, recPerPage=100, searchKeyword, filter}) => {
    let data = {
        page: page,
        recPerPage: recPerPage,
        q: searchKeyword,
        filters:filter
    }
    let res = await postAPI('/project/list', data)
    return res;
}

export const validateFormData = (formData) => {
    const{
        // clientID,
        fullName,
        managerName,
        countryID, 
        languageID,
        categoryID,
        // description, 
        loi,
        ir,
        sampleSize,
        respondentClickQuota,
        currencyID,
        projectCPI,
        supplierCPI,
        startDate,
        endDate
    } = formData;
    const newErrors = {};
    // if(!clientID    || clientID === '') newErrors.clientID = 'Please enter this field';
    if(!fullName    || fullName === '') newErrors.fullName = 'Please enter this field';
    if(!managerName || managerName === '') newErrors.managerName = 'Please enter this field';
    if(!countryID   || countryID === 'Select Country') newErrors.countryID = 'Please select country';
    if(!languageID  || languageID === 'Select Language') newErrors.languageID = 'Please select language';
    if(!categoryID  || categoryID === 'Select Language') newErrors.categoryID = 'Please select category';
    // if(!description || description === '') newErrors.description = 'Please enter this field';
    if(!loi?.toString() || loi === '') newErrors.loi = 'Please enter this LOI';
    if(!ir?.toString() || ir === '') newErrors.ir = 'Please enter this IR';
    if(!sampleSize?.toString() || sampleSize === '') newErrors.sampleSize = 'Please enter sample size';
    if(!currencyID  || currencyID === 'Select Language') newErrors.currencyID = 'Please select currency';
    if(!projectCPI  || projectCPI === '') newErrors.projectCPI = 'Please enter this field';
    if(!supplierCPI || supplierCPI === '') newErrors.supplierCPI = 'Please enter this field';
    if(!startDate   || startDate === '') newErrors.startDate = 'Please enter this field';
    if(!endDate     || endDate === '') newErrors.endDate = 'Please enter this field';
    if(!respondentClickQuota?.toString() || respondentClickQuota === '') newErrors.respondentClickQuota = 'Please enter respondent click quota';
    return newErrors
}

export const ProjectCreateSingle = async({payload}) => {
    let res = await postAPI('/project/single/save/',payload)
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}

export const cloneData = async({payload}) => {
    let res = await postAPI('/project/clone/',payload)
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}

export const ProjectDetail = async({payload}) => {
    let res = await get('/project/single/details/'+payload)
    return res;
}

export const ProjectSupplierListMaster = async() => {
    return await get('/project/get-supplier')
}

export const ProjectSupplierMappedSingle = async({payload, projectDetailID}) => {
    let res = await postAPI('/project/single/'+projectDetailID+'/add-supplier', payload)
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}

export const ProjectSupplierList = async({projectId}) => {
    let res = await postAPI('/project/single/'+projectId+'/supplier-list')
    return res;
    // return Swal.fire({
    //     text:res.message,
    //     timer: 1500,
    // }).then(() => {
    //     return res;
    // })
}

export const ProjectMappedSupperDetail = async({supplierId, projecctDetailId}) => {
    let res = await get('/project/single/'+projecctDetailId+'/supplier-details/'+supplierId)
    return res;
}

export const ProjectSupplierMappedSingleUpdate = async({payload, projectDetailID, supplierDetailId}) => {
    let res = await putAPI('/project/single/'+projectDetailID+'/update-supplier/'+supplierDetailId, payload)
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}

export const ProjectMappedSupperDelete = async({supplierId, projecctDetailId}) => {

    let message = 'Are you sure you want to delete?'
    
    return Swal.fire({
        text: message,
        confirmButtonText: 'Yes',
        showCancelButton: true,
      }).then(async (result) => {
        if (result.value) {
            let res = await deleteAPI('/project/single/'+projecctDetailId+'/delete-supplier/'+supplierId)
            return Swal.fire({
                text:res.message,
                timer: 1500,
            }).then(() => {
                return res;
            })
        }
      })    
}

export const ProjectUpdateSingle = async({id, payload}) => {
    let res = await putAPI('/project/single/update/'+id,payload)
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}

export const ProjectUpdateStatusSingle = async({id, status}) => {
    let res = await putAPI('/project/single/update-status/'+id+'/'+status)
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}

export const ProjectDelete = async({id, payload}) => {
    let message = 'Are you sure you want to delete?'
    if(payload.type === 'group'){
        message = 'All child project will removed once deleted parent project. Are you sure you want to delete?'
    }
    return Swal.fire({
        text: message,
        confirmButtonText: 'Yes',
        showCancelButton: true,
      }).then(async (result) => {
        if (result.value) {
            let res = await deleteAPI('/project/delete/'+id, payload)
            return Swal.fire({
                text:res.message,
                timer: 1500,
            }).then(() => {
                return res;
            })
        }
      })
}

export const ProjectMappedSupplierSettingUpdate = async({supplierId, projectDetailId, payload}) => {
    let message = 'Are you sure you want to update?'
    return Swal.fire({
        text: message,
        confirmButtonText: 'Yes',
        showCancelButton: true,
      }).then(async (result) => {
        if (result.value) {
            let res = await putAPI('/project/single/'+projectDetailId+'/update-supplier-settings/'+supplierId, payload)
            return Swal.fire({
                text:res.message,
                timer: 1500,
            }).then(() => {
                return res;
            })
        }
      })
}

export const getSurveyLink = async({id}) => {
    let res = await get('/project/single/survey-link/'+id)
    return res;
}
export const saveSurveyLink = async({id, payload}) => {
    let res = await postAPI('/project/single/survey-link/add/'+id, payload)
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}

export const getSngleProjectReport = async({id}) => {
    return Swal.fire({
        text:'Loading...',
        timer: 1500,
        showConfirmButton:false
    }).then(async () => {
        let res = await get('/project/single/download-report/'+id)
        if(res.status === 1){
            return Swal.fire({
                text:res.message,
                timer: 1500,
            }).then(() => {
                return res;
            })
        }
    })
}

export const downloadGroupProjectReport = async({payload}) => {
    return Swal.fire({
        text:'Loading...',
        timer: 1500,
        showConfirmButton:false
    }).then(async () => {
        let res = await postAPI('/reports/download/group/', payload)
        return Swal.fire({
            text:res.message,
            timer: 1500,
        }).then(() => {
            return res;
        })
    })
}

export const getSingleProjectSecurity = async({projectDetailID, securityType}) => {
    let res = await get('/project/single/security/'+projectDetailID+'/'+securityType)
    return res;
}

export const updateSingleProjectSecurity = async({projectDetailID, securityType, payload}) => {
    let res = await postAPI('/project/single/security/update/'+projectDetailID+'/'+securityType, payload)
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}

// Prescreen tab
        // -----> prescreen Message
export const addUpdatePrescreenMessage = async({payload}) => {
    let res = await postAPI('/project/prescreen/save-message', payload)
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}

export const getPrescreenMessageData = async({payload}) => {
    let res = await get('/project/prescreen/get-message/'+payload?.projectDetailID)
    return res
}

        // --------> get template list
export const getProjectTemplatelist = async() => {
    return await get('/project/prescreen/template/list')
}

export const addQueByTemplate = async({payload}) => {
    let res = await postAPI('/project/precsreen/map-question-from-template/', payload)
    return res;
}

// Temp question 
export const TempQueList = async({page=0, recPerPage=100, searchKeyword, projectDetailID}) => {
    let data = {
        page: page,
        recPerPage: recPerPage,
        q: searchKeyword,
        projectDetailID: projectDetailID,
    }
    let res = await postAPI('/project/prescreen/get-temp-question-list', data)
    return res;
}

export const TempQueCreate = async({payload}) => {
    let res = await postAPI('/project/prescreen/add-temp-question', payload)
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}

export const TempQueDetail = async({payload}) => {
    let res = await postAPI('/project/prescreen/get-temp-question-detail/',payload)
    return res;
}

export const TempQueUpdate = async({id, payload}) => {
    let res = await postAPI('/project/prescreen/update-temp-question', payload)
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}

export const TempQueDelete = async({payload}) => {
    return Swal.fire({
        text: 'Are you sure you want to delete?',
        confirmButtonText: 'Yes',
        showCancelButton: true,
      }).then(async (result) => {
        if (result.value) {
            let res = await deleteAPI('/project/prescreen/delete-temp-question', payload)
            return Swal.fire({
                text:res.message,
                timer: 1500,
            }).then(() => {
                return res;
            })
        }
      })
}

export const TempQueTitleUiqueCheck = async({payload}) => {
    let res = await postAPI('/project/prescreen/check-unique-question-title', payload)
    return res
}

export const getAllMappedQuestion = async({payload}) => {
    let res = await postAPI('/project/prescreen/get-mapped-questions', payload)
    return res;
}

export const getClientReport = async({payload}) => {
    return Swal.fire({
        text:'Loading...',
        timer: 1500,
        showConfirmButton:false
    }).then(async () => {
        let res = await postAPI('/reports/download/client/', payload)
        return Swal.fire({
            text:res.message,
            timer: 1500,
        }).then(() => {
            return res;
        })
    })
}

export const getStatisticsReport = async({payload}) => {
    return Swal.fire({
        text:'Loading...',
        timer: 1500,
        showConfirmButton:false
    }).then(async () => {
        let res = await postAPI('/reports/download/statistics/', payload)
        return Swal.fire({
            text:res.message,
            timer: 1500,
        }).then(() => {
            return res;
        })
    })
}

export const getRDReport = async({payload}) => {
    return Swal.fire({
        text:'Loading...',
        timer: 1500,
        showConfirmButton:false
    }).then(async () => {
        let res = await postAPI('/reports/download/rd/', payload)
        return Swal.fire({
            text:res.message,
            timer: 1500,
        }).then(() => {
            return res;
        })
    })
}

export const getProjectActivityReport = async({payload}) => {
    return Swal.fire({
        text:'Loading...',
        timer: 1500,
        showConfirmButton:false
    }).then(async () => {
        let res = await postAPI('/reports/download/project-activity/', payload)
        return Swal.fire({
            text:res.message,
            timer: 1500,
        }).then(() => {
            return res;
        })
    })
}

export const getSupplierReport = async({payload}) => {
    return Swal.fire({
        text:'Loading...',
        timer: 1500,
        showConfirmButton:false
    }).then(async () => {
        let res = await postAPI('/reports/download/supplier/', payload)
        return Swal.fire({
            text:res.message,
            timer: 1500,
        }).then(() => {
            return res;
        })
    })
}

export const getProjectRelation = async({projectDetailID,objectType}) => {
    let res = await get('/project/relation/get/'+projectDetailID+'/'+objectType)
    return res;
}
export const updateProjectRelation = async(payload) => {
    let res = await postAPI('/project/relation/sync/', payload);
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}