import React from "react";
import { deleteAPI, postAPI, putAPI, get } from "../ApiService";
import Swal from "sweetalert2";

export const reconciliationList = async ({
  mfIdentifier,
  page = 1,
  perPage = 100,
  searchTerm,
}) => {
  let data = {
    mfIdentifier: mfIdentifier,
    page: page,
    recPerPage: perPage,
    q: searchTerm,
  };
  let res = await postAPI("/conciliation/reconciliationList", data);

  return res;
};

export const getSingleReconciliationDownlodReport = async ({
  mfIdentifier,
}) => {
  try {
    const res = await postAPI("/conciliation/reconciliation-report", {
      mfIdentifier,
    });

    if (res.status === 1) {
      return res;
    } else {
      console.error("Failed to generate report:", res.message);
      return res;
    }
  } catch (error) {
    console.error("Error generating report:", error);
    throw error;
  }
};
