import React, { useEffect, useState } from "react";
import { get, postAPI } from "../../../ApiService";
import Swal from "sweetalert2";

function PreScreenChooseTemplate(props){
    const [selectedTemplate, setSelectedTemplate] = useState()
    const [projectTemplateList, setProjectTemplateList] = useState()

    useEffect(() => {
        getTemplatelist()
    },[])

    const getTemplatelist = async() => {
        let res = await get('/project/prescreen/template/list');
        if(res?.status === 1){
            setProjectTemplateList(res?.payload)
        }
    }
    const addTemplateToProject = async() => {
        let payload = {
            projectDetailID: parseInt(props?.detail?.projectDetailID),
            templateID: selectedTemplate
        }
        // let res = await addQueByTemplate({payload:payload})
        let res = await postAPI('/project/precsreen/map-question-from-template/', payload)
        Swal.fire({
            text:res.message,
            // timer: 1500,
        })
        props?.mappedQue()
    }
    return (<>
        <div className="mb- ">
            <div>
                <label htmlFor="Choosetemplate" className="form-label mb-0">Choose Template</label>
                <div className='d-flex'>
                    <select id="Choosetemplate" className="form-select" data-choices data-choices-sorting="true" onChange={(e) => setSelectedTemplate(e.target.value)}>
                        <option value={""} disabled selected>Select Template</option>
                        {projectTemplateList?.map((row) => {
                            return (<>
                                <option value={row?.ID}>{row?.title}</option>
                            </>)
                        })}
                    </select>
                    <span className="btn btn-primary mx-2" onClick={()=>addTemplateToProject()}>Add</span>
                </div>
            </div>

        </div>
    </>)
}
export default PreScreenChooseTemplate