import React, { useState, useEffect } from "react";
import Footer from "../Layout/Footer";
import Header from "../Layout/Header";
import Sidebar from "../Layout/Sidebar";
import Pagination from "../Layout/Pagination";
import { defaultPage } from "../../Common/Constant";
import { IPTrackerList, AddBlockIP } from "../../Api/Ip_tracker";

const Ip_tracker = () => {
  const [searchIP, setSearchIP] = useState("");
  const [comment, setComment] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [trackerData, setTrackerData] = useState([]);
  const [page, setPage] = useState(1);
  const [pageInfo, setPageInfo] = useState({});
  const [dataCount, setDataCount] = useState();

  useEffect(() => {
    document.title = "IP Tracker - Mindforce";
    fetchTrackerData();
  }, [searchIP, searchTerm, page]);
  const fetchTrackerData = async () => {
    try {
      const response = await IPTrackerList({
        searchIP: searchIP,
        page: pageInfo.current_page || 1,
        recPerPage: defaultPage.perPage,
        searchTerm: searchTerm,
      });
      if (response.status === 1) {
        setTrackerData(response.payload);
        setDataCount(response.dataCount);
        setPageInfo(response.paginationInfo);
      } else {
        setTrackerData([]);
      }
    } catch (error) {
      console.error("Error fetching tracker data:", error);
      setTrackerData([]);
    }
  };

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    // setPage(1);
    fetchTrackerData();
  };

  const handleBlock = async (e) => {
    e.preventDefault();
    try {
      const response = await AddBlockIP({
        ip: searchIP,
        comment: comment,
      });
      setComment("");
    } catch (error) {
      console.error("Error blocking IP:", error);
    }
  };

  const changePage = (p) => {
    pageInfo.current_page = p;
    setPage(p);
    fetchTrackerData();
  };

  return (
    <div>
      <Sidebar />
      <Header />
      <div id="layout-wrapper">
        <div className="main-content">
          <div className="page-content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                    <h4 className="mb-sm-0">IP Tracker</h4>
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <a href="/">Support</a>
                        </li>
                        <li className="breadcrumb-item active">IP Tracker</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-xl-12 col-lg-12">
                  <div className="card">
                    <div className="card-body">
                      <form onSubmit={handleSearchSubmit}>
                        <div className="row">
                          <div className="col-md-4 col-lg-4">
                            <div className="mb-3">
                              <label
                                htmlFor="searchIP"
                                className="form-label mb-0"
                              >
                                Search IP
                              </label>
                              <div className="d-flex">
                                <input
                                  type="search"
                                  className="form-control"
                                  id="searchIP"
                                  value={searchIP}
                                  onChange={(e) => setSearchIP(e.target.value)}
                                  placeholder="xxxx xxxx xxx"
                                />
                                <button
                                  type="submit"
                                  className="btn btn-primary mx-2"
                                >
                                  Search
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-8 col-lg-8">
                            <div className="mb-3">
                              <label
                                htmlFor="comment"
                                className="form-label mb-0"
                              >
                                Comment
                              </label>
                              <div className="d-flex">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="comment"
                                  value={comment}
                                  onChange={(e) => setComment(e.target.value)}
                                  placeholder="Enter comment"
                                />
                                <button
                                  type="button"
                                  className="btn btn-primary mx-2"
                                  onClick={handleBlock}
                                >
                                  Block
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                      <div className="row justify-content-between mt-3">
                        <div className="col-md-2 col-lg-2">
                          {/* Page Size Select is commented out */}
                        </div>
                        <div className="col-md-4 col-lg-4">
                          <div className="text-end">
                            <div className="search">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                autoComplete="off"
                                id="search-options"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table className="table table-striped table-hover table_client mt-3">
                          <thead>
                            <tr
                              className="bg-primary"
                              style={{ color: "white" }}
                            >
                              <th scope="col">Sr.No.</th>
                              <th scope="col">ProjectCode</th>
                              <th scope="col">ProjectName</th>
                              <th scope="col">SupplierCode</th>
                              <th scope="col">SupplierName</th>
                              <th scope="col">SupplierIdentifier</th>
                              <th scope="col">Status</th>
                              <th scope="col">Date</th>
                              <th scope="col">Geo.Location</th>
                              <th scope="col">DeviceType</th>
                              <th scope="col">BrowserDetail</th>
                            </tr>
                          </thead>
                          <tbody>
                            {trackerData.length > 0 ? (
                              trackerData.map((item, index) => (
                                <tr key={index}>
                                  <th scope="row">
                                    {(page - 1) * pageSize + index + 1}
                                  </th>
                                  <td>{item.projectCode}</td>
                                  <td>{item.projectName}</td>
                                  <td>{item.supplierCode}</td>
                                  <td>{item.supplierName}</td>
                                  <td>{item.supplierIdentifier}</td>
                                  <td>{item.status}</td>
                                  <td>{item.date}</td>
                                  <td>{item.geoLocation}</td>
                                  <td>{item.deviceType}</td>
                                  <td>{item.browserDetails}</td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="11" className="text-center">
                                  No data found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="pagination-wrapper">
                        <Pagination
                          pageInfo={pageInfo}
                          changePage={changePage}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Ip_tracker;
