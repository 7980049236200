import React from 'react'
import Footer from '../../Layout/Footer'
import Header from '../../Layout/Header'
import Sidebar from '../../Layout/Sidebar'
import Preloader from '../../Layout/Preloader';
import Select from 'react-dropdown-select';
import { useEffect, useState, useRef } from 'react'
import { getClientList } from '../../../Api/Report'
import { getClientReport } from '../../../Api/Project'

function Client_report() {
    useEffect(() => {
        document.title = "Client Report - Mindforce"
    },);
    const ddlClient = useRef();
    const [lstClients, setClients] = useState([]);
    const [selClientID, setClientID] = useState('');
    const [clientReportView, setClientReportView] = useState([]);
    const [startDate, setStartTimestamp] = useState();
    const [endDate, setEndTimestamp] = useState();

    useEffect(() => {
        getClients();
    }, []);

    const setTimestamp = (d, t) => {
        if(t === 'startDate')
        setStartTimestamp((Date.parse(d))/1000);
        else if(t === 'endDate')
        setEndTimestamp((Date.parse(d))/1000);
    }

    const getClients = async() => {
        let res = await getClientList();
        if(res.status === 1){
            let arrClients = []
            res.payload?.map((row) => {
                arrClients.push(
                    {
                        "clientID": row.clientID,
                        "clientName": row.clientCode + ': ' + row.clientName,
                    }
                )}
            )
            setClients(arrClients);
        } else
        setClients([]);
    };

    const getDownloadReport = async(e) => {
        e.preventDefault();
        let payload = {
            "clientID": selClientID,
            "startDate": startDate,
            "endDate": endDate,
            "viewType": 'download'
        }
        let resClntRpt = await getClientReport({payload});
        if(resClntRpt.status === 1){
            window.open(resClntRpt?.payload?.reportURL);
        } 
    };

    const getViewReport = async(e) => {
        e.preventDefault();
        let payload = {
            "clientID": selClientID,
            "startDate": startDate,
            "endDate": endDate,
            "viewType": 'list'
        }
        let resClntRpt = await getClientReport({payload});
        if(resClntRpt.status === 1){
            setClientReportView(resClntRpt?.payload)
        }
    };

    const getClearSelection = (e) => {
        e.preventDefault();
        setClientReportView([]);
        setClientID('');
        setStartTimestamp('');
        setEndTimestamp('');
    };

    return (
        <div>
            <Sidebar />
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            <div className="row">
                                <div className="col-12 col-lg-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Client Report</h4>

                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a >Report</a></li>
                                                <li className="breadcrumb-item active">Client Report</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}

                            <div className="row">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">

                                        {/* <!-- end card header --> */}

                                        <div className="card-body">
                                            <div className="report">
                                                <form >
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="ForminputState" className="form-label mb-0">Client <span className="required_field">*</span></label>
                                                                <Select
                                                                    id="ddlClient"
                                                                    className="form-control"
                                                                    options={lstClients}
                                                                    labelField="clientName"
                                                                    valueField="clientID"
                                                                    searchBy="clientName"
                                                                    onChange={(values) => setClientID(values[0]?.clientID)}
                                                                    placeholder="Select Client"
                                                                    ref={ddlClient}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="mb-3">
                                                                        <label htmlFor="Projectdescription" className="form-label mb-0">From <span className="required_field">*</span></label>
                                                                        <input type="date" className="form-control" id="startDate" max={new Date().toISOString().split("T")[0]} onChange={() => setTimestamp(document.getElementById('startDate').value, 'startDate')} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="mb-3">
                                                                        <label htmlFor="exampleInputdate" className="form-label mb-0">To <span className="required_field">*</span></label>
                                                                        <input type="date" className="form-control" id="endDate" max={new Date().toISOString().split("T")[0]} onChange={() => setTimestamp(document.getElementById('endDate').value, 'endDate')} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 col-lg-4">
                                                        <div className="report_btn">
                                                                <button type="submit" className="btn btn-primary" onClick={(e) => getViewReport(e)}>View</button>
                                                                <button type="submit" className="btn btn-primary mx-2" onClick={(e) => getDownloadReport(e)}>Download</button>
                                                                <button type="submit" className="btn btn-primary" onClick={(e) => getClearSelection(e)}>Reset</button>
                                                               
                                                            </div>
                                                        </div>
                                                        {/* <!--end col--> */}
                                                       
                                                     
                                                        {/* <!--end col--> */}
                                                       


                                                       


                                                        {/* <!--end col--> */}
                                                    </div>
                                                    {/* <!--end row--> */}
                                                    <div className='row'>
                                                        {clientReportView ? (
                                                                <>
                                                                <div className='hScrollDiv'>
                                                                    <table className="table table-striped table-hover  table_client">
                                                                        {
                                                                            clientReportView?.length > 0 ? (
                                                                                <>
                                                                                    <thead>
                                                                                        <tr className="bg-primary" style={{ color: "white" }}>
                                                                                            <th>S.No.</th>
                                                                                            <th style={{ textAlign:"left" }}>Project Code</th>
                                                                                            <th style={{ textAlign:"left" }}>Project Name</th>
                                                                                            <th style={{ textAlign:"left" }}>Country</th>
                                                                                            <th>Complete</th>
                                                                                            <th>Terminate</th>
                                                                                            <th>OverQuota</th>
                                                                                            <th>QualityTerm</th>
                                                                                            <th>DropOut</th>
                                                                                            <th>DuplicateUser</th>
                                                                                            <th>DuplicateIP</th>
                                                                                            <th>PSStart</th>
                                                                                            <th>PSTerminate</th>
                                                                                            <th>PSDropOut</th>
                                                                                            <th>Inactive</th>
                                                                                            <th>ProjectCompleteExhausted</th>
                                                                                            <th>SupplierCompleteExhausted</th>
                                                                                            <th>Fraud User</th>
                                                                                            <th>RDFailure</th>
                                                                                            <th>TestLink</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {
                                                                                            clientReportView?.map((row, i) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <tr>
                                                                                                            <td scope="row">{i + 1}</td>
                                                                                                            <td style={{ textAlign:"left" }}>{row?.projectCode}</td>
                                                                                                            <td style={{ textAlign:"left" }}>{row?.projectName}</td>
                                                                                                            <td style={{ textAlign:"left" }}>{row?.projectCountry}</td>
                                                                                                            <td>{row?.completes}</td>
                                                                                                            <td>{row?.terminates}</td>
                                                                                                            <td>{row?.overQuota}</td>
                                                                                                            <td>{row?.qualityTerm}</td>
                                                                                                            <td>{row?.overQuota}</td>
                                                                                                            <td>{row?.duplicateUser}</td>
                                                                                                            <td>{row?.duplicateIP}</td>
                                                                                                            <td>{row?.psStart}</td>
                                                                                                            <td>{row?.psTerminate}</td>
                                                                                                            <td>{row?.psOverQuota}</td>
                                                                                                            <td>{row?.psDropOut}</td>
                                                                                                            <td>{row?.inactive}</td>
                                                                                                            <td>{row?.projectCompleteExhausted}</td>
                                                                                                            <td>{row?.supplierCompleteExhausted}</td>
                                                                                                            <td>{row?.fraudUser}</td>
                                                                                                            <td>{row?.rdFailure}</td>
                                                                                                            <td>{row?.testLink}</td>
                                                                                                        </tr>
                                                                                                    </>
                                                                                                );
                                                                                            })
                                                                                        }
                                                                                    </tbody>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                   <tr>
                                                                                        <td colSpan={13}>
                                                                                            <center>No client data found</center>
                                                                                        </td>
                                                                                    </tr> 
                                                                                </>
                                                                            )
                                                                        }
                                                                    </table>
                                                                </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Preloader />
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                {/* <!-- end col --> */}









                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Client_report