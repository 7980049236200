import { deleteAPI, get, postAPI, putAPI } from "../ApiService";
import Swal from "sweetalert2";
export const PreScreenTemplateList = async({page=0, recPerPage=100, searchKeyword, filters}) => {
    let data = {
        page: page,
        recPerPage: recPerPage,
        q: searchKeyword,
        filters:filters
    }
    let res = await postAPI('/prescreen/template/list', data)
    return res;
}

export const PreScreenTemplateDetail = async({payload}) => {
    let res = await get('/prescreen/template/details/'+payload?.templateID)
    return res;
}

export const addPreScreenTemplate = async({payload}) => {
    let res = await postAPI('/prescreen/template/add', payload)
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}

export const preScreenTemplatenameUpdate = async({payload}) => {
    let res = await putAPI('/prescreen/template/update/'+payload?.id, payload)
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}

export const preScreenSearchQuestion = async ({payload}) => {
    let res = await get('/prescreen/search-question/?q='+payload?.q+'&languageID='+payload?.languageID)
    return res;
}

export const addQuestionTPreScreenTemplate = async({payload}) => {
    let res = await postAPI('/prescreen/template/add-question', payload)
    return Swal.fire({
        text:res.message,
        timer: 1500,
    }).then(() => {
        return res;
    })
}

export const preScreenTemplateQuestionPriorityUpdate = async({payload}) => {
    let res = await putAPI('/prescreen/template/update-question-priority', payload)
    return res
}

export const preScreenTemplateDelete = async({payload}) => {
    return Swal.fire({
        text: 'Are you sure you want to delete?',
        confirmButtonText: 'Yes',
        showCancelButton: true,
      }).then(async (result) => {
        if (result.value) {
            let res = await deleteAPI('/prescreen/template/delete/'+payload?.ID)
            return Swal.fire({
                text:res.message,
                timer: 1500,
            }).then(() => {
                return res;
            })
        }
      })
}

export const preScreenTemplateQueDelete = async({payload}) => {
    return Swal.fire({
        text: 'Are you sure you want to delete?',
        confirmButtonText: 'Yes',
        showCancelButton: true,
      }).then(async (result) => {
        if (result.value) {
            let res = await deleteAPI('/prescreen/template/delete-question', payload)
            return Swal.fire({
                text:res.message,
                timer: 1500,
            }).then(() => {
                return res;
            })
        }
      })
}

export const updateQuePriority = async({payload}) => {
    return await postAPI('/prescreen/template/update-question-priority',payload);
}

export const downloadPrescreenReport = async({payload}) => {
    return await get('/project/prescreen/download-report/'+payload?.projectDetailId)
}

