import React from "react";
import { deleteAPI, postAPI, putAPI, get } from "../ApiService";
import Swal from "sweetalert2";

export const IPTrackerList = async ({
  page = 1,
  recPerPage = 100,
  searchIP,
  searchTerm,
}) => {
  let data = {
    page: page,
    recPerPage: recPerPage,
    q: searchTerm,
    requestedIP: searchIP,
  };
  let res = await postAPI("/tracker/trackerList", data);

  return res;
};

export const AddBlockIP = async ({ ip, comment }) => {
  let data = {
    ip: ip,
    comment: comment,
  };
  let res = await postAPI("/IP/block/ip", data);

  return Swal.fire({
    text: res.message,
    timer: 1500,
  }).then(() => {
    return res;
  });
};
