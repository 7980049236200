import React from "react";
import { deleteAPI, postAPI, putAPI, get } from "../ApiService";
import Swal from "sweetalert2";

export const IPList = async ({ page = 1, recPerPage = 100, searchTerm }) => {
  let data = {
    page: page,
    recPerPage: recPerPage,
    q: searchTerm,
  };
  let res = await postAPI("/IP/block/list", data);

  return res;
};

export const IPDelete = async (id) => {
  return Swal.fire({
    text: "Are you sure you want to delete?",
    confirmButtonText: "Yes",
    showCancelButton: true,
  }).then(async (result) => {
    if (result.value) {
      let res = await deleteAPI("/IP/block/delete/" + id);
      return Swal.fire({
        text: res.message,
        timer: 1500,
      }).then(() => {
        return res;
      });
    }
  });
};
