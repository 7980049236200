import React, { useState } from "react";
import { downloadPrescreenReport } from "../../../Api/PreScreen";
import { getSngleProjectReport } from "../../../Api/Project";

function TabSingleReportDownload(props){
    const [type, setType] = useState('project');
    const downloadReport = async () => {
        //console.log('type', type)
        if(type === 'project'){
            let res = await getSngleProjectReport({ id: props?.detail?.projectDetailID })
            if (res.status === 1) {
                window.open(res?.payload?.reportURL)
            }
        }else if(type === 'prescreen'){
            let payload = {
                projectDetailId: props?.detail?.projectDetailID
            }
            let res = await downloadPrescreenReport({payload})
            if (res.status === 1) {
                window.open(res?.payload?.reportURL)
            }
        }
        
    }
    return (<>
    <div className="card-body">
        <div>
            <div className='row'>
                <div className='col-lg-3 col-md-3'>
                    <div className="form-check form-radio-outline form-radio-info mb-3">
                        <input className="form-check-input" type="radio" name="formradiocolor14" checked={true} id="formradioRight18" onClick={(e) => setType('project')} />
                        <label className="form-check-label"htmlFor="formradioRight18">
                            Project Report
                        </label>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3'>
                    <div className="form-check form-radio-outline form-radio-info mb-3">
                        <input className="form-check-input" type="radio" name="formradiocolor14" id="prescreenReport" onClick={(e) => setType('prescreen')} />
                        <label className="form-check-label"htmlFor="prescreenReport">
                            PreScreen Report
                        </label>
                    </div>
                </div>
            </div>
            <div className="col-lg-12">
                <div className="text-end">
                    <button type="submit" className="btn btn-primary mx-2" onClick={() => downloadReport()}>Download</button>

                </div>
            </div>

        </div>
    </div>
    </>)
}
export default TabSingleReportDownload