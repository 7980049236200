import { id } from "date-fns/locale";
import React, { useState, useMemo, useCallback, useEffect } from "react";
import { masterData } from "../../../Api/Master";
import { getSingleProjectSecurity, updateSingleProjectSecurity } from "../../../Api/Project";
import RangeSlider from '../RangeSelect';

function TabSingleSecurity(props){
    let defaultRdSecurityTab
    let rdSTrue = 0;
    const projectDetailID = props?.detail?.projectDetailID
    for (let index = 0; index < props?.detail?.securityFilters.length; index++) {
        const ele = props?.detail?.securityFilters?.[index];
        
        if(ele.id === 426 && ele.status === 1){
            rdSTrue = 1
            defaultRdSecurityTab = "rdSecurity"
        }else if(ele.id === 427 && ele.status === 1){

            if(rdSTrue === 1){
                defaultRdSecurityTab = "rdSecurity"
            }else{
                defaultRdSecurityTab = "rdText"
            }
            
        }
    }
    
    const [rdSecurityTab, setrdSecurityTab] = useState(defaultRdSecurityTab)
    const [parentVal, setParentVal] = useState(50);
    const [parentVal1, setParentVal1] = useState(50);
    const [parentVal2, setParentVal2] = useState(50);
    const [ActiveCheckall, setActiveCheckall] = useState(false);
    const [ActiveCheckallText, setActiveCheckallText] = useState(false);
    const [rdSecurityList, setRDSecurity] = useState()
    const [selectedRDSecurity, setSelectedRDSecurity] = useState([]);
    const [rdTextList, setRDText] = useState()
    const [selectedRDText, setSelectedRDText] = useState([]);

    const rdSecurityTabChange = (e) => {
        setrdSecurityTab(e.target.value)
    }
    useEffect(() => {
        getRDSecurityList();
        getRDTextList()
    },[])

    useEffect(() => {
        getRDSelectedData()
        if(rdSecurityTab === 'rdText'){
            getRDTSelectedData()
        }
    },[rdSecurityTab])

    const getRDSecurityList = async() => {
        let res = await masterData({ moduleName: 'rd-security' })
        if (res.status === 1) {
            setRDSecurity(res.payload)
        }
    }

    const getRDTextList = async() => {
        let res = await masterData({ moduleName: 'rd-text' })
        if (res.status === 1) {
            setRDText(res.payload)
        }
    }

    const getRDSelectedData = async() => {
        let res = await getSingleProjectSecurity({projectDetailID:projectDetailID, securityType:"rd-security"})
        let selected_count = 0;
        if(res.status === 1){
            for (let index = 0; index < res.payload.length; index++) {
                const ele = res.payload[index];
                if(ele.status === 1){
                    selected_count += 1;
                    document.getElementById('chkDef'+ele.id).checked = true;
                    if(ele.id === 448){
                        setParentVal1(ele.value)
                    }
                    if(ele.id === 447){
                        setParentVal(ele.value)
                    }
                }
            }
            if(res.payload.length === selected_count){
                let getCHk = document.getElementById("rdsChkAll")
                getCHk.checked = true;
            }
            setSelectedRDSecurity(res.payload)
        }
    }

    const getRDTSelectedData = async() => {
        let res = await getSingleProjectSecurity({projectDetailID:projectDetailID, securityType:"rd-text"})
        let selected_count = 0;
        if(res.status === 1){
            for (let index = 0; index < res.payload.length; index++) {
                const ele = res.payload[index];
                if(ele.status === 1){
                    selected_count += 1;
                    document.getElementById('chkDefRdt'+ele.id).checked = true;
                    if(ele.id === 459){
                        setParentVal(ele.value)
                    }
                    if(ele.id === 460){
                        setParentVal1(ele.value)
                    }
                    if(ele.id === 461){
                        setParentVal2(ele.value)
                    }
                }
            }
            if(res.payload.length === selected_count){
                let getCHk = document.getElementById("rdtChkAll")
                getCHk.checked = true;
            }
            setSelectedRDText(res.payload)
        }
    }

    const sliderProps = useMemo(
        () => ({
            min: 0,
            max: 100,
            value: parentVal,
            step: 2,

            onChange: e => sliderValueChanged(e)
        }),
        [parentVal]
    );
    const sliderProps1 = useMemo(
        () => ({
            min: 0,
            max: 100,
            value: parentVal1,
            step: 2,

            onChange: e => sliderValueChanged1(e)
        }),
        [parentVal1]
    );

    const sliderProps2 = useMemo(
        () => ({
            min: 0,
            max: 100,
            value: parentVal2,
            step: 2,

            onChange: e => sliderValueChanged2(e)
        }),
        [parentVal2]
    );

    const sliderValueChanged = useCallback(val => {
        setParentVal(val);
    });
    const sliderValueChanged1 = useCallback(val => {
        setParentVal1(val);
    });
    const sliderValueChanged2 = useCallback(val => {
        setParentVal2(val);
    });

    const handleActiveCheckAll = async(e) => {
        const { value, checked } = e.target;
        let getCHk = document.getElementsByClassName("chkDef")
        if (checked) {
            let selRdsSec = []
            rdSecurityList?.map((rds) => {
                selRdsSec.push(rds)
            })
            for (let index = 0; index < getCHk.length; index++) {
                const element = getCHk[index];
                element.checked = true;
            }
            setSelectedRDSecurity(selRdsSec)
        }
        if (!checked) {
            setSelectedRDSecurity([])
            for (let index = 0; index < getCHk.length; index++) {
                const element = getCHk[index];
                element.checked = false;
            }
        }
    }

    const handleRDSIndividual = (e) => {
        const { value, checked } = e.target;
        let selRdsSec = selectedRDSecurity
        if (checked) {
            selRdsSec.push(JSON.parse(value))
        }else{
            let index = selRdsSec.findIndex(srds=>srds.id === JSON.parse(value).id)
            selRdsSec.splice(index, 1);
        }
        if(selRdsSec?.length !== rdSecurityList?.length){
            let getCHk = document.getElementById("rdsChkAll")
            getCHk.checked = false;
        }
        setSelectedRDSecurity(selRdsSec)
    }

    const handleActiveCheckAllrdText = (e) => {
        const { value, checked } = e.target;
        let getCHk = document.getElementsByClassName("chkDefRdt")
        if (checked) {
            let selRdtSec = []
            rdTextList?.map((rdt) => {
                selRdtSec.push(rdt)
            })
            for (let index = 0; index < getCHk.length; index++) {
                const element = getCHk[index];
                element.checked = true;
            }
            setSelectedRDText(selRdtSec)
        }
        if (!checked) {
            setSelectedRDText([])
            for (let index = 0; index < getCHk.length; index++) {
                const element = getCHk[index];
                element.checked = false;
            }
        }
    }

    const handleRDTIndividual = (e) => {
        const { value, checked } = e.target;
        let selRdTSec = selectedRDText
        if (checked) {
            selRdTSec.push(JSON.parse(value))
        }else{
            let index = selRdTSec.findIndex(srdt=>srdt.id === JSON.parse(value).id)
            selRdTSec.splice(index, 1);
        }
        if(selRdTSec?.length !== rdTextList?.length){
            let getCHk = document.getElementById("rdtChkAll")
            getCHk.checked = false;
        }else{
            let getCHk = document.getElementById("rdtChkAll")
            getCHk.checked = true;
        }
        setSelectedRDText(selRdTSec)
    }

    const submitRDSecurity = async () => {
        let selData = [];
        rdSecurityList.forEach(ele => {
            let index = selectedRDSecurity.findIndex(srds=>srds.id === ele.id)
            let status = 0;
            let value = null
            if(index > -1){
                status = 1;
            }
            if(ele.id === 447 && index > -1){
                value = parentVal
            }
            if(ele.id === 448 && index > -1){
                value = parentVal1
            }
            selData.push({
                id: ele.id,
                status: status,
                value: value
            },)
        });
        let payload = {
            security : selData
        }
        let res = await updateSingleProjectSecurity({projectDetailID:projectDetailID, securityType:"rd-security", payload:payload})
    }


    const submitRDText = async () => {
        let selData = [];
        rdTextList.forEach(ele => {
            let index = selectedRDText.findIndex(srdt=>srdt.id === ele.id)
            let status = 0;
            let value = null
            if(index > -1){
                status = 1;
            }
            if(ele.id === 459 && index > -1){
                value = parentVal
            }
            if(ele.id === 460 && index > -1){
                value = parentVal1
            }
            if(ele.id === 461 && index > -1){
                value = parentVal2
            }
            selData.push({
                id: ele.id,
                status: status,
                value: value
            },)
        });
        let payload = {
            security : selData
        }
        let res = await updateSingleProjectSecurity({projectDetailID:projectDetailID, securityType:"rd-text", payload:payload})
    }

    
    return (<>
    <div className="card-body">
        <div>
            <div className='mb-3'>
                {props?.detail?.securityFilters?.[0]?.id === 426 && props?.detail?.securityFilters?.[0]?.status === 1 ? (
                    <>
                    <button className={rdSecurityTab === "rdSecurity" ? "btn btn-primary" : "btn bggray"} value='rdSecurity' onClick={(e) => rdSecurityTabChange(e)} >RD Security</button>
                    </>
                ) : (<></>)}
                
                {props?.detail?.securityFilters?.[1]?.id === 427 && props?.detail?.securityFilters?.[1]?.status === 1 ? (
                    <>
                    <button className={rdSecurityTab === "rdText" ? "btn btn-primary mx-2" : "btn bggray mx-2"} value='rdText' onClick={(e) => rdSecurityTabChange(e)} >RD Text</button>
                    </>
                ) : (<></>)}
                
                
            </div>
            {rdSecurityTab === 'rdSecurity' && <div className='row'>
                <div className="col-md-12">
                    <div className="form-check mb-3">
                        <input className="form-check-input" type="checkbox" id="rdsChkAll" onClick={(e) => handleActiveCheckAll(e)} />
                        <label className="form-check-label" htmlFor="rdsChkAll">
                            Select All
                        </label>
                    </div>
                </div>
                {rdSecurityList?.map((rds) => {
                    return (<>
                    <div className='row'>
                        <div className="col-md-6">
                            <div className='row'>
                                <div className="col-md-6">
                                    <div className="form-check mb-3">
                                        <input 
                                            className="form-check-input chkDef" 
                                            id={"chkDef"+rds.id}
                                            type="checkbox" 
                                            value={JSON.stringify(rds)} 
                                            defaultChecked={selectedRDSecurity.findIndex(srds=>srds.id === rds.id) > -1 ? true : false}
                                            onChange={(e) => handleRDSIndividual(e)} />
                                        <label className="form-check-label" htmlFor={"chkDef"+rds.id}>
                                            {rds?.name}
                                        </label>
                                    </div>
                                </div>
                                {rds?.id === 447 ? (<>
                                    <div className="col-md-6">
                                        <RangeSlider {...sliderProps} classes="additional-css-classes" />
                                    </div>
                                </>) : (<></>)}

                                {rds?.id === 448 ? (<>
                                    <div className="col-md-6">
                                        <RangeSlider {...sliderProps1} classes="additional-css-classes" />
                                    </div>
                                </>) : (<></>)}
                                
                            </div>
                        </div>
                    </div>
                    </>)
                })}
                {/* <div className='row'>
                    <div className="col-md-6">
                        <div className='row'>
                            <div className="col-md-6">
                                <div className="form-check mb-3">
                                    {ActiveCheckall ?

                                        <><input className="form-check-input" type="checkbox" checked />
                                        </> :
                                        <><input className="form-check-input" type="checkbox" />
                                        </>
                                    }
                                    <label className="form-check-label">
                                        Duplicate entrant into survey
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <RangeSlider {...sliderProps} classes="additional-css-classes" />
                            </div>
                        </div>
                    </div>
                </div> */}
                
                <div className="col-lg-12">
                    <div className="text-end">
                        <button type="submit" className="btn btn-primary" onClick={() => submitRDSecurity()} >Submit</button>
                    </div>
                </div>
            </div>
            }
            {rdSecurityTab === 'rdText' && <div className='row'>

                <div className="col-md-12">
                    <div className="form-check mb-3">
                        <input className="form-check-input" type="checkbox" id="rdtChkAll" onClick={(e) => handleActiveCheckAllrdText(e)} />
                        <label className="form-check-label" htmlFor="rdtChkAll">
                            Select All
                        </label>
                    </div>
                </div>
                {rdTextList?.map((rdt) => {
                    return (<>
                    <div className='row'>
                    <div className="col-md-6">
                        <div className='row'>
                            <div className="col-md-6">
                                <div className="form-check mb-3">
                                    <input className="form-check-input" type="checkbox" />
                                    <input 
                                        className="form-check-input chkDefRdt" 
                                        id={"chkDefRdt"+rdt.id}
                                        type="checkbox" 
                                        value={JSON.stringify(rdt)} 
                                        defaultChecked={selectedRDText.findIndex(srdt=>srdt.id === rdt.id) > -1 ? true : false}
                                        onChange={(e) => handleRDTIndividual(e)} />
                                    <label className="form-check-label" htmlFor={"chkDefRdt"+rdt.id}> {rdt.name} </label>
                                </div>
                            </div>
                            {rdt.id === 459 ? (<>
                                <div className="col-md-6">
                                    <RangeSlider {...sliderProps} classes="additional-css-classes" />
                                </div>
                            </>) : (<></>)}

                            {rdt.id === 460 ? (<>
                                <div className="col-md-6">
                                    <RangeSlider {...sliderProps1} classes="additional-css-classes" />
                                </div>
                            </>) : (<></>)}

                            {rdt.id === 461 ? (<>
                                <div className="col-md-6">
                                    <RangeSlider {...sliderProps2} classes="additional-css-classes" />
                                </div>
                            </>) : (<></>)}
                            
                        </div>
                    </div>
                </div>
                    </>)
                })}

                <div className="col-lg-12">
                    <div className="text-end">
                        <button type="submit" className="btn btn-primary" onClick={() => submitRDText()}>Submit</button>
                    </div>
                </div>
            </div>
            }
        </div>
    </div>
    </>)
}
export default TabSingleSecurity