import React from 'react'
import Footer from '../../Layout/Footer'
import Header from '../../Layout/Header'
import Sidebar from '../../Layout/Sidebar'
import Preloader from '../../Layout/Preloader';
import Select from 'react-dropdown-select';
import { useEffect, useState } from 'react'
import { getGroupProjectReport, getAllProjectReport } from '../../../Api/Report';
import { downloadGroupProjectReport } from "../../../Api/Project";

function Group_report() {
    useEffect(() => {
        document.title = "Group Report - Mindforce"
    },);

    const [lists, setLists] = useState();
    const [childProjects, setChildProjects] = useState([]);
    const [selProjectID, setProjectID] = useState();
    const [allProjSel, setAllProjectChecked] = useState(false);
    const [selChildProjIDs, setChildProjIds] = useState([]);

    useEffect(() => {
        getReport();
    }, []);

    useEffect(() => {
        manageChkBoxState();
    }, [selChildProjIDs]);

    const manageChkBoxState = () => {
        let chkCProj = document.getElementsByName('chkChildProjects');
        if(selChildProjIDs.length > 0){
            if(selChildProjIDs.length === childProjects.length)
            document.getElementById('allChildProject').checked = true;
            else
            document.getElementById('allChildProject').checked = false;
        }

        for(let c = 0 ; c < chkCProj.length; c++){
            if(selChildProjIDs.indexOf(chkCProj[c].value) > -1)
            chkCProj[c].checked = true;
            else
            chkCProj[c].checked = false;
        }
    }
    const setAllChildSelected = (e) => {
        let newArr = [];
        let chk = e.target;
        let chkCProj = document.getElementsByName('chkChildProjects');
        if(chk.checked){
            for(let c = 0 ; c < chkCProj.length; c++)
            newArr.push(chkCProj[c].value);
        } 
        setChildProjIds(newArr);
    }

    const setChildProjId = (e) => {
        let newArr = selChildProjIDs.slice();
        let chk = e.target;
        let prjId = chk.value;
        if(chk.checked){
            newArr.push(prjId);
        }else {
            const index = newArr.indexOf(prjId);
            if (index > -1) { // only splice array when item is found
                newArr.splice(index, 1); // 2nd parameter means remove one item only
            }
        }
        setChildProjIds(newArr);
    }

    const getReport = async() => {
        let res = await getGroupProjectReport();
        if (res.status === 1) {
            let prjList = [];

            res.payload?.map((row) => {
                    prjList.push(
                        {
                            "projectID": row.projectID,
                            "projectFullName": row.projectCode + ': ' + row.projectFullName,
                        }
                    )
                }
            )
            setLists(prjList);
        } else 
        setLists([]);
    }

    const getChildReports = async(e) => {
        e.preventDefault();
        let res = await getAllProjectReport(selProjectID);
        if (res.status === 1) {
            setChildProjects(res.payload);
        } else 
        setChildProjects([]);
    }

    const downloadReport = async (e) => {
        e.preventDefault();
        // console.clear();
        // console.log(selChildProjIDs);
        let payload = {
            "filter": [
                {
                    param: "projectDetailID",
                    value: selChildProjIDs
                }
            ]
        }
        let res = await downloadGroupProjectReport({ payload })
        if (res.status === 1)
        window.open(res?.payload?.reportURL)
        
    }

    return (
        <div>
            <Sidebar />
            <Header />
            <div id="layout-wrapper">
                <div className="main-content">

                    <div className="page-content">
                        <div className="container-fluid">

                            <div className="row">
                                <div className="col-12 col-lg-12">
                                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                        <h4 className="mb-sm-0">Group Project Report</h4>

                                        <div className="page-title-right">
                                            <ol className="breadcrumb m-0">
                                                <li className="breadcrumb-item"><a >Report</a></li>
                                                <li className="breadcrumb-item active">Group Project Report</li>
                                            </ol>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            {/* <!-- end page title --> */}

                            <div className="row">
                                <div className="col-xl-12 col-lg-12">
                                    <div className="card">

                                        {/* <!-- end card header --> */}

                                        <div className="card-body">
                                            <div className="report">
                                                <form >
                                                    <div className="row">
                                                   
                                                    <div className="col-md-4">
                                                            <div className="mb-3">
                                                                <label htmlFor="ForminputState" className="form-label mb-0">Group Project <span className="required_field">*</span></label>
                                                                <Select
                                                                    className="form-control"
                                                                    options={lists}
                                                                    labelField="projectFullName"
                                                                    valueField="projectID"
                                                                    searchBy="projectFullName"
                                                                    onChange={(values) => setProjectID(values[0]?.projectID)}
                                                                    placeholder="Select Project"
                                                                />
                                                            </div>
                                                        </div>
                                                   
                                                        <div className="col-md-8 col-lg-8">
                                                        <div className="report_btn">
                                                                <button type="submit" className="btn btn-primary" onClick={(e) => getChildReports(e)}>View</button>
                                                                <button type="submit" className="btn btn-primary mx-2" onClick={(e) => downloadReport(e)}>Download</button>
                                                             
                                                               
                                                            </div>
                                                        </div>
                                                        {/* <!--end col--> */}
                                                       
                                                     
                                                        {/* <!--end col--> */}
                                                       


                                                       


                                                        {/* <!--end col--> */}
                                                    </div>
                                                    {/* <!--end row--> */}
                                                    <div className='row'>
                                                        {childProjects ? (
                                                                <>
                                                                <div className='hScrollDiv'>
                                                                    <table className="table table-striped table-hover  table_client">
                                                                        {
                                                                            childProjects?.length > 0 ? (
                                                                                <>
                                                                                    <thead>
                                                                                        <tr className="bg-primary" style={{ color: "white" }}>
                                                                                            <th>S.No.</th>
                                                                                            <th>
                                                                                                <input className="form-check-input" type="checkbox" id="allChildProject" onClick={(e) => setAllChildSelected(e)} />
                                                                                            </th>
                                                                                            <th>Project Code</th>
                                                                                            <th style={{ textAlign:"left" }}>Project Name</th>
                                                                                            <th style={{ textAlign:"left" }}>Country</th>
                                                                                            <th>Complete</th>
                                                                                            <th>Terminate</th>
                                                                                            <th>OverQuota</th>
                                                                                            <th>QualityTerm</th>
                                                                                            <th>DropOut</th>
                                                                                            <th>Active</th>
                                                                                            <th>Reject</th>
                                                                                            <th>DuplicateUser</th>
                                                                                            <th>DuplicateIP</th>
                                                                                            <th>GeoIP</th>
                                                                                            <th>DeviceType</th>
                                                                                            <th>BlockedIP</th>
                                                                                            <th>Speeder</th>
                                                                                            <th>Exclude</th>
                                                                                            <th>PSStart</th>
                                                                                            <th>PSTerminate</th>
                                                                                            <th>PSOverQuota</th>
                                                                                            <th>PSDropOut</th>
                                                                                            <th>SurveyClose</th>
                                                                                            <th>Inactive</th>
                                                                                            <th>TrafficDisabled</th>
                                                                                            <th>ProjectCompleteExhausted</th>
                                                                                            <th>ProjectClickExhausted</th>
                                                                                            <th>SupplierCompleteExhausted</th>
                                                                                            <th>SupplierClickExhausted</th>
                                                                                            <th>MultiLinkNotAvailable</th>
                                                                                            <th>IdentifierNotExist(Recontact)</th>
                                                                                            <th>Fraud User</th>
                                                                                            <th>TSFailure</th>
                                                                                            <th>RDFailure</th>
                                                                                            <th>TestLink</th>
                                                                                            <th>Action</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {
                                                                                            childProjects?.map((project, i) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <tr>
                                                                                                            <td scope="row">{i + 1}</td>
                                                                                                            <td><input className="form-check-input" type="checkbox" id={"childProject-" + (i+1)} name="chkChildProjects" value={project?.projectDetailID} onClick={(e) => {setChildProjId(e)}} /></td>
                                                                                                            <td>{project?.projectDetailCode}</td>
                                                                                                            <td style={{ textAlign:"left" }}>{project?.projectDetailFullName}</td>
                                                                                                            <td style={{ textAlign:"left" }}>{project?.countryName}</td>
                                                                                                            <td>{project?.complete}</td>
                                                                                                            <td>{project?.terminate}</td>
                                                                                                            <td>{project?.overQuota}</td>
                                                                                                            <td>{project?.qualityTerm}</td>
                                                                                                            <td>{project?.overQuota}</td>
                                                                                                            <td>{project?.active}</td>
                                                                                                            <td>{project?.reject}</td>
                                                                                                            <td>{project?.duplicateUser}</td>
                                                                                                            <td>{project?.duplicateIP}</td>
                                                                                                            <td>{project?.geoIP}</td>
                                                                                                            <td>{project?.deviceType}</td>
                                                                                                            <td>{project?.blockedIP}</td>
                                                                                                            <td>{project?.speeder}</td>
                                                                                                            <td>{project?.exculde}</td>
                                                                                                            <td>{project?.psStart}</td>
                                                                                                            <td>{project?.psTerminate}</td>
                                                                                                            <td>{project?.psOverQuota}</td>
                                                                                                            <td>{project?.psDropOut}</td>
                                                                                                            <td>{project?.surveyClose}</td>
                                                                                                            <td>{project?.inactive}</td>
                                                                                                            <td>{project?.trafficDisabled}</td>
                                                                                                            <td>{project?.projectCompleteExhausted}</td>
                                                                                                            <td>{project?.supplierCompleteExhausted}</td>
                                                                                                            <td>{project?.supplierClickExhausted}</td>
                                                                                                            <td>{project?.multiLinkNotAvailable}</td>
                                                                                                            <td>{project?.identifierNotExist}</td>
                                                                                                            <td>{project?.fraudUser}</td>
                                                                                                            <td>{project?.tsFailure}</td>
                                                                                                            <td>{project?.rdFailure}</td>
                                                                                                            <td>{project?.testLink}</td>
                                                                                                            <td>Download</td>
                                                                                                        </tr>
                                                                                                    </>
                                                                                                );
                                                                                            })
                                                                                        }
                                                                                    </tbody>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                   <tr>
                                                                                        <td colSpan={13}>
                                                                                            <center>No project found</center>
                                                                                        </td>
                                                                                    </tr> 
                                                                                </>
                                                                            )
                                                                        }
                                                                    </table>
                                                                </div>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Preloader />
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                {/* <!-- end col --> */}









                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Group_report