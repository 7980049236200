import React, { useState, useEffect } from "react";
import { masterData } from "../../../Api/Master";
import { getProjectRelation, updateProjectRelation } from "../../../Api/Project";

function TabGroupChildReInvite(props){
    const [redirectStatusList, setRedirectStatusList] = useState([]);
    const [selectedRedirectStatus, setSelectedRedirectStatus] = useState([]);
    const projectDetailID = props?.detail?.childProjects?.[0]?.projectDetailID;

    useEffect(() => {
        const fetchData = async () => {
            await getRedirectStatusList();
            await getRedirectStatusSelectedData();
        };
        fetchData();
    }, [projectDetailID]);

    const getRedirectStatusList = async () => {
        let res = await masterData({ moduleName: 'redirect-status' });
        if (res.status === 1) {
            setRedirectStatusList(res.payload);
        }
    };

    const getRedirectStatusSelectedData = async () => {
        let res = await getProjectRelation({ projectDetailID, objectType: "reinvite" });
        if (res.status === 1) {
            const selectedData = await res.payload.map(ele => ele.objectID);
            const chkDef = document.getElementsByClassName("chkDef");
            let chckCount = 0;
            for (let i = 0; i < chkDef.length; i++) {
                const chk = chkDef[i];
                chk.checked = selectedData.includes(parseInt(chk.value));
                if (chk.checked) {
                    chckCount++;
                }
            }
            let chckDefCount = chkDef.length;
            if (chckCount === chckDefCount) {
                document.getElementById("chkAll").checked = true;
            }
            
            setSelectedRedirectStatus([...new Set(selectedData)]);
        }
    };
    const handleActiveCheckAll = (e) => {
        const { checked } = e.target;
        if (checked) {
            const allIds = redirectStatusList.map(rds => rds.id);
            setSelectedRedirectStatus([...new Set(allIds)]);
        } else {
            setSelectedRedirectStatus([]);
        }

        const chkDef = document.getElementsByClassName("chkDef");
        for (let i = 0; i < chkDef.length; i++) {
            chkDef[i].checked = checked;
        }
    };

    const handleIndividual = (e) => {
        const { value, checked } = e.target;
        setSelectedRedirectStatus(prevSelected => {
            const newSelected = checked
                ? [...prevSelected, parseInt(value)]
                : prevSelected.filter(id => id !== parseInt(value));
            if (newSelected.length !== redirectStatusList.length) {
                document.getElementById("chkAll").checked = false;
            }
            return [...new Set(newSelected)];
        });
    };

    const submitReInvite = async () => {
        const payload = {
            projectDetailID,
            objectType: "reinvite",
            objectIDs: selectedRedirectStatus
        };
        let res = await updateProjectRelation(payload);
        // Handle response if needed
    };
    
    return (<>
    <div className="card-body">
        <div>
            <div className='row'>
                <div className="col-md-12">
                    <div className="form-check mb-3">
                        <input type="checkbox"
                        id="chkAll"
                        className="form-check-input"
                        onChange={(e) => handleActiveCheckAll(e)}/>
                        {/* defaultChecked={selectedRedirectStatus.length === redirectStatusList.length && redirectStatusList.length > 0}  */}
                        <label className="form-check-label" htmlFor="chkAll">
                            Select All
                        </label>
                    </div>
                </div>
                {redirectStatusList?.map((rds) => {
                    return (<>
                    <div className='row'>
                        <div className="col-md-6">
                            <div className='row'>
                                <div className="col-md-6">
                                    <div className="form-check mb-3">
                                        <input 
                                            type="checkbox"
                                            id={`chkDef${rds.id}`}
                                            className="chkDef form-check-input"
                                            value={rds.id}
                                            defaultChecked={selectedRedirectStatus.includes(rds.id)}
                                            onChange={(e) => handleIndividual(e)} />
                                        <label className="form-check-label" htmlFor={"chkDef"+rds.id}>
                                            {rds?.name}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </>)
                })}                
                <div className="col-lg-12">
                    <div className="text-end">
                        <button type="submit" className="btn btn-primary" onClick={() => submitReInvite()} >Submit</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>)
}
export default TabGroupChildReInvite