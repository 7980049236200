import { deleteAPI, get, postAPI, putAPI } from "../ApiService";
import Swal from "sweetalert2";
export const getSingleProjectReport = async() => {
    let res = await get('/reports/get-project/single/')
    return res;
}
export const getAllProjectReport = async(gProjectID = null) => {
    let res = await get('/reports/get-project/all/' + (gProjectID !== null && !isNaN(gProjectID) ? '?projectID=' + gProjectID : ''))
    return res;
}
export const getGroupProjectReport = async() => {
    let res = await get('/reports/get-project/group/')
    return res;
}
export const getClientList = async() => {
    let res = await get('/reports/get-client/')
    return res;
}

export const getSupplierList = async() => {
    let res = await get('/reports/get-supplier/')
    return res;
}